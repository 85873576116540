
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { feedbackApi } from '@/api/modules/feedback'

export default defineComponent({
  name: 'helpCenterFeedbackShow',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()

    onBeforeMount(() => {
      const { id } = route.query
      data.getInfo(Number(id))
    })

    const data: any = reactive({
      info: null,

      // 获取详情
      async getInfo (id: number) {
        const { res } = await feedbackApi.getInfo({ id })
        console.log('获取详情', res)

        res.pic = JSON.parse(res.pic)
        if (!res.remark) {
          res.remark = '-'
        }

        data.info = res
      },

      // 返回
      onReturn () {
        router.go(-1)
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
